<template>
    <footer class="block-footer gb-section" v-if="footer">
        <div class="block-footer-wrapper gb-wrapper">
            <UltraColText columns="three" class="block-footer-col-left">
                <img class="block-footer-brand" src="@/assets/images/dummont-logo.png" alt="SoftLine"/>
                <p v-html="footer.description" class="mt-20" v-if="footer.description"></p>
            </UltraColText>
            <UltraColText columns="three" class="block-footer-col-center">
                <UltraBtn class="textOnly" :action="footer.callCenter.action" v-if="footer.callCenter"
                          color="transparent" size="textSmall"/>
                <UltraBtn class="mt-20" :action="footer.support.action" v-if="footer.support" size="small textSmall"
                          color="primary"/>
                <UltraBtn class="mt-20 textOnly textUnderline" :action="footer.privacyPolice.action"
                          v-if="footer.privacyPolice" color="transparent" size="textSmall"/>
            </UltraColText>
            <UltraColText columns="three" class="block-footer-col-right">
                <p class="block-footer-sponsor-text">Um produto</p>
                <img src="@/assets/images/softline-logo.png" alt="SoftLine" class="block-footer-sponsor-logo" @click.prevent="openBrandLink"/>
                <ul class="block-footer-list" v-if="footer.social">
                    <li class="block-footer-list-item" v-for="item in footer.social" :key="item.id">
                        <UltraBtn :action="item" color="transparent" class="rounded medium"/>
                    </li>
                </ul>
            </UltraColText>
        </div>
        <img :src="footer.image" v-if="footer.image" class="block-footer-image-bg" alt="Imagem de fundo">
    </footer>
    <UltraPrivacyMessage/>
</template>

<script>
import UltraPrivacyMessage from "@/components/UltraPrivacyMessage";
import UltraColText from "@/components/UltraColText.vue";
import UltraBtn from "@/components/UltraBtn.vue";
import mixins from "@/mixins";

export default {
    name: "BlockFooter",
    components: {UltraBtn, UltraColText, UltraPrivacyMessage},
    mixins: [mixins],
    computed: {
        footer() {
            return this.$store.getters['footer'];
        },
    },
    methods: {
        openBrandLink() {
            const {urlSponsored} = this.footer;
            if (!urlSponsored) return;
            const linkSponsored = {
                url: urlSponsored,
                target: '_blank',
            };
            this.openLink(linkSponsored);
        },
    }
}
</script>

<style scoped>
.block-footer {
    background-color: transparent;
    position: relative;
    padding: 100px 0;
    color: var(--color-white);
}

.block-footer-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
}

.block-footer-image-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    object-position: center center;
    z-index: -1;
    height: 100%;
    width: 100%;
}

.block-footer-sponsor-text {
    margin-bottom: -20px;
    margin-right: 14px;
    text-align: right;
    font-size: .9rem;
    opacity: .6;
    pointer-events: none;
}

.block-footer-sponsor-logo {
    cursor: pointer;
}

.block-footer-col-right {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 25%;
}

.block-footer-list {
    display: inline-flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 20px 0 0 0;
}

.block-footer-brand {
    width: 70%;
}

.block-footer-col-center {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
}

.block-footer-col-left {
    width: 40%;
}

@media only screen and (max-width: 600px) {
    .block-footer-wrapper {
        flex-direction: column;
        text-align: center;
    }

    .block-footer-col-right {
        width: 100%;
        align-items: center;
    }

    .block-footer-brand {
        width: calc(100% - 20px);
    }

    .block-footer-col-center {
        width: 100%;
    }

    .block-footer-col-left {
        width: 100%;
    }

    .block-footer-sponsor-text {
        margin-bottom: -20px;
        margin-right: 14px;
        text-align: center;
        font-size: .9rem;
        opacity: .6;
        width: 100%;
        padding-left: 110px;
    }
}
</style>
