<template>
  <UltraBox v-if="highlight" class="block-highlight">
    <UltraColText columns="two" data-aos="zoom-in-right">
      <h3 class="fn-color primary mb-20" v-if="highlight.title" v-html="highlight.title"></h3>
      <p v-if="highlight.description" v-html="highlight.description"></p>
    </UltraColText>
    <UltraColText columns="two" data-aos="zoom-in-left" v-if="highlight.image">
      <UltraLink :action="highlight.action" v-if="highlight.action" style="position: relative;">
        <img class="img-rounded" :src="highlight.image" alt="image" />
        <i class="icon-play-circle-fill ultra-large img-icon opacity" v-if="highlight.url === '#video'"></i>
      </UltraLink>
      <img class="img-rounded" :src="highlight.image" alt="image" v-else />
    </UltraColText>
  </UltraBox>
</template>

<script>
import UltraBox from "@/components/UltraBox";
import UltraColText from "@/components/UltraColText";
import UltraLink from "@/components/UltraLink";

export default {
  name: "BlockHighlight",
  components: {UltraLink, UltraColText, UltraBox},
  computed: {
    highlight() {
      return this.$store.getters['highlight'];
    },
  },
}
</script>

<style scoped>
.img-icon.opacity {
  opacity: .6;
}

@media only screen and (max-width: 600px) {
  .block-highlight {
    text-align: center;
  }
}
</style>
