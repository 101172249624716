<template>
  <div class="ultra-divider">
    <div class="ultra-divider-left"></div>
    <div class="ultra-divider-center"></div>
    <div class="ultra-divider-right"></div>
  </div>
</template>

<script>
export default {
  name: "UltraDivider",
}
</script>

<style scoped>
.ultra-divider {
  width: 100%;
  height: 42px;
  position: relative;
  margin-top: -42px;
  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  color: var(--color-white);
  background-image: linear-gradient(to right, currentColor 0%, currentColor 35%, transparent 40%, transparent 60%, currentColor 65%, currentColor 100%);
}
.ultra-divider-center {
  width: 200px;
}
.ultra-divider-left {
  display: inline-block;
  flex: 1;
  background-color: currentColor;
  mask-image: url("../assets/images/bar-bottom-left.svg");
  mask-repeat: no-repeat;
  mask-size: auto 100%;
  mask-position: right bottom;
  position: relative;
}
.ultra-divider-right {
  display: inline-block;
  flex: 1;
  background-color: currentColor;
  mask-image: url("../assets/images/bar-bottom-right.svg");
  mask-repeat: no-repeat;
  mask-size: auto 100%;
  mask-position: left bottom;
  position: relative;
}
@media only screen and (max-width: 1280px) {
  .ultra-divider {
    background-image: linear-gradient(to right, currentColor 0%, currentColor 10%, transparent 15%, transparent 85%, currentColor 90%, currentColor 100%);
  }
}
@media only screen and (max-width: 600px) {
  .ultra-divider {
    background-image: linear-gradient(to right, currentColor 0%, currentColor 5%, transparent 10%, transparent 90%, currentColor 95%, currentColor 100%);
  }
}
</style>