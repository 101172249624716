<template>
  <section class="block-movie gb-section" v-if="movie">
    <img :src="movie.image" alt="slide-top" v-if="movie.image" data-aos="slide-down" />
    <div class="block-movie-wrapper gb-wrapper">
      <div class="block-movie-card" data-aos="zoom-in-left">
        <h2 v-if="movie.title">{{ movie.title }}</h2>
      </div>
      <div class="block-movie-button" data-aos="zoom-in" v-if="movie.url">
        <UltraLink :action="movie.action">
          <i class="icon-play-fill ultra-large"></i>
        </UltraLink>
      </div>
    </div>
  </section>
</template>

<script>
import UltraLink from "@/components/UltraLink";
export default {
  name: "BlockMovie",
  components: {UltraLink},
  computed: {
    movie() {
      return this.$store.getters['movie'];
    }
  },
}
</script>

<style scoped>
.block-movie {
  overflow: hidden;
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  color: var(--color-white);
}
.block-movie > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
}
.block-movie-wrapper {
  height: 100%;
  min-height: 50vw;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.block-movie-card {
  width: calc(50% - 100px);
  text-align: right;
}
.block-movie-button {
  width: 200px;
  height: 200px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--color-secondary);
  cursor: pointer;
  transition: color ease-in-out .5s;
}
.block-movie-button:hover {
  color: var(--color-white);
  transition: color ease-in-out .5s;
}
.block-movie-button:before {
  content: '';
  display: inline-block;
  width: 164px;
  height: 164px;
  position: absolute;
  left: 18px;
  top: 18px;
  border-radius: 50%;
  background-color: var(--color-primary-dark);
  opacity: .6;
  transition: all ease-in-out .3s;
  pointer-events: none;
}
.block-movie-button:after {
  content: '';
  display: inline-block;
  width: 164px;
  height: 164px;
  position: absolute;
  left: 28px;
  top: 28px;
  border-radius: 50%;
  border: 2px solid var(--color-secondary);
  pointer-events: none;
}
.block-movie-button:hover:before {
  opacity: .8;
  transform: scale(1.2);
  transition: all ease-in-out .3s;
  filter: blur(10px);
}
@media only screen and (max-width: 600px) {
  .block-movie-wrapper {
    min-height: 80vh;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  .block-movie-card {
    width: 100%;
    text-align: center;
  }
  .block-movie-button {
    width: 200px;
    height: 200px;
    margin-bottom: calc(100% - 100px);
  }
}
</style>