<template>
  <div class="ultra-card-comment" v-if="comment" @click.prevent="openComment">
    <header class="ultra-card-comment-header" data-aos="zoom-in-down">
      <img :src="comment.image" class="ultra-card-comment-image mb-10" :alt="comment.name" v-if="comment.image" />
      <p v-if="comment.name">{{ comment.name }}<span v-if="comment.age">, {{ comment.age }}</span></p>
    </header>
    <section class="ultra-card-comment-section" :class="{ opened: opened }">
      <p v-if="comment.message" v-html="comment.message"></p>
      <i class="icon-chevron-down small" v-if="!opened" />
      <i class="icon-chevron-up small" v-else />
    </section>
  </div>
</template>

<script>
export default {
  name: "UltraCardComment",
  data() {
    return {
      opened: false,
    };
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openComment() {
      this.opened = !this.opened;
    },
  },
}
</script>

<style scoped>
.ultra-card-comment {
  width: 100%;
  height: auto;
  text-align: center;
  cursor: pointer;
}
.ultra-card-comment-header {
  margin-bottom: 20px;
  position: relative;
  display: inline-flex;
  justify-content: stretch;
  align-items: center;
  flex-direction: column;
}
.ultra-card-comment-header p {
  font-weight: 600;
}
.ultra-card-comment-header span {
  font-weight: 300;
}
.ultra-card-comment-header:before {
  width: 100px;
  height: 2px;
  background-color: var(--color-secondary);
  position: absolute;
  bottom: -10px;
  content: '';
}
.ultra-card-comment-section {
  padding: 0 20px;
  overflow: hidden;
}
.ultra-card-comment-section p {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.ultra-card-comment-section.opened p {
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
}
.ultra-card-comment-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center center;
  overflow: hidden;
  border-radius: 50%;
}
.ultra-card-comment-section p {
  line-height: 130%;
}

@media only screen and (max-width: 600px) {
  .ultra-card-comment {
    width: calc(100% - 60px);
  }
}
</style>
