<template>
    <header class="ultra-header gb-section" :class="{ active: active }">
        <section class="ultra-header-wrapper gb-wrapper">
            <div class="ultra-header-logo" ref="boxLogo" :style="boxLogoStyles" @click="scrollTopPage">
                <img src="../assets/images/dummont-logo.png" alt="Infinity"/>
            </div>
            <div class="ultra-header-menu" v-if="menu || social" :class="{ active: menuActive }">
                <ul class="ultra-header-menu-list" v-if="menu.length" data-aos="zoom-in-up">
                    <li class="item-link" v-for="item in menu" :key="item.id">
                        <UltraLink :action="item.action"/>
                    </li>
                </ul>
                <ul class="ultra-header-menu-social" v-if="social.length" data-aos="zoom-in-up">
                    <li class="item-link" v-for="item in social" :key="item.icon">
                        <UltraLink :action="item.action"/>
                    </li>
                </ul>
                <ul class="ultra-header-menu-signIn" v-if="signIn" data-aos="zoom-in-up">
                    <UltraBtn :action="signIn.action" size="small" color="primary"/>
                </ul>
                <div class="ultra-header-menu-mobile_action_menu" @click.prevent="toggleMenu">
                    <i class="icon-menu" data-aos="zoom-in-left" v-if="!menuActive"></i>
                    <i class="icon-x" data-aos="zoom-in-left" v-else></i>
                </div>
            </div>
        </section>
    </header>
</template>

<script>
import UltraBtn from "@/components/UltraBtn";
import UltraLink from "@/components/UltraLink";
import mixins from "@/mixins";

export default {
    name: "UltraHeader",
    mixins: [mixins],
    components: {UltraLink, UltraBtn},
    data() {
        return {
            min: 90,
            max: 180,
            scroll: null,
        };
    },
    computed: {
        menu() {
            return this.$store.getters['menu'];
        },
        menuActive() {
            return this.$store.getters['menuActive'];
        },
        social() {
            return this.$store.getters['social'];
        },
        signIn() {
            return this.$store.getters['signIn'];
        },
        boxLogoStyles() {
            const {min, max, scroll} = this;
            const middleValue = (scroll === 0) ? max : (scroll < min) ? max - scroll : min;
            return {
                maxHeight: `${middleValue}px`,
            };
        },
        active() {
            const {min, scroll} = this;
            return scroll >= min;
        },
    },
    mounted() {
        this.startScrollControl();
    },
    beforeUnmount() {
        this.finishScrollControl();
    },
    methods: {
        startScrollControl() {
            const {boxLogo} = this.$refs;
            if (!boxLogo) return;
            document.addEventListener('scroll', this.checkScrollControl);
        },
        checkScrollControl() {
            this.scroll = window.top.scrollY;
        },
        finishScrollControl() {
            document.removeEventListener('scroll', this.checkScrollControl);
        },
        toggleMenu() {
            this.$store.commit('toggleMenu');
        },
    },
}
</script>

<style scoped>
.ultra-header {
    height: auto;
    min-height: 90px;
    position: fixed;
    top: 0;
    color: var(--color-white);
    z-index: 1000;
}

.ultra-header:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 90px;
    background-color: transparent;
    transition: background-color .5s ease-in-out;
}

.ultra-header.active:before {
    background-color: #64778D;
    transition: background-color .5s ease-in-out;
}

.ultra-header-wrapper {
    flex-direction: row;
    justify-content: space-between;
}

.ultra-header-logo {
    width: 270px;
    max-height: 180px;
    height: auto;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 0 10px 0;
    cursor: pointer;
}

.ultra-header-logo > img {
    height: 100%;
}

.ultra-header-menu {
    position: relative;
    width: auto;
    flex: 1;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 90px;
}

.ultra-header-menu-list {
    position: relative;
    width: auto;
    flex: 1;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 90px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.ultra-header-menu-social {
    position: relative;
    width: auto;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 90px;
    list-style: none;
    padding: 0 0 0 10px;
    margin: 0;
}

.ultra-header-menu-signIn {
    position: relative;
    width: auto;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 90px;
    list-style: none;
    padding: 0 0 0 10px;
    margin: 0;
}

.item-link {
    padding: 5px;
    text-transform: uppercase;
    font-weight: 400;
    color: var(--color-white);
    transition: color ease-in-out .3s;
    cursor: pointer;
    width: auto;
    overflow: hidden;
}

.item-link:hover {
    color: var(--color-secondary-light);
    transition: color ease-in-out .3s;
}

.item-link > a {
    color: inherit;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
}

.item-link > a > i {
    pointer-events: none;
}

.ultra-header-menu-mobile_action_menu {
    position: relative;
    width: auto;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 90px;
    display: none;
}

@media only screen and (max-width: 600px) {
    .ultra-header-menu {
        position: fixed;
        top: 100px;
        left: 10px;
        width: calc(100vw - 20px);
        height: 0;
        max-height: 0;
        padding: 0;
        overflow: hidden;
        background-color: #64778D;
        flex-direction: column;
        border-radius: 20px;
        pointer-events: none;
        transition: all ease-in-out .3s;
    }

    .ultra-header-menu.active {
        overflow: hidden;
        height: auto;
        max-height: calc(100vh - 110px);
        padding: 40px 0;
        transition: all ease-in-out .3s;
    }

    .ultra-header-menu-list {
        width: 100%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        pointer-events: all;
    }

    .ultra-header-menu-list {
        width: 100%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        pointer-events: all;
    }

    .ultra-header-menu-social {
        width: 100%;
        height: auto;
        flex-direction: row;
        justify-content: center;
        padding: 0;
        pointer-events: all;
    }

    .ultra-header-menu-signIn {
        width: 100%;
        height: auto;
        flex-direction: row;
        justify-content: center;
        padding: 0;
        pointer-events: all;
    }

    .ultra-header-logo {
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
    }

    .ultra-header-menu-mobile_action_menu {
        position: fixed;
        top: 0;
        left: 0;
        height: 90px;
        width: 60px;
        display: inline-flex;
        justify-content: center;
        pointer-events: all;
    }

    .item-link {
        min-height: 60px;
    }

    .ultra-header-logo > img {
        height: calc(100% - 10px);
    }
}
</style>
