<template>
  <section class="block-modules gb-section" v-if="modules">
    <div class="block-modules-wrapper gb-wrapper">
      <h2 class="mb-20 tx-uppercase" data-aos="zoom-in-down">{{ modules.title }}</h2>
      <h3 class="mb-20" data-aos="zoom-in-down">{{ modules.description }}</h3>
      <UltraAccordion :items="modules.items" />
    </div>
    <img src="@/assets/images/plane-chunks.png" class="block-modules-image" alt="bottom-image" />
  </section>
</template>

<script>
import UltraAccordion from "@/components/UltraAccordion";

export default {
  name: "BlockModules",
  components: {UltraAccordion},
  computed: {
    modules() {
      return this.$store.getters['modules'];
    },
  },
}
</script>

<style scoped>
.block-modules {
  background-image: linear-gradient(90deg, #868686 10.43%, #454545 93.74%);
  color: var(--color-white);
  padding: 60px 0 120px 0;
}
.block-modules-wrapper {
  text-align: center;
  padding: 60px 0 20px 0;
  background: var(--color-primary-dark);
  border-radius: 30px;
}
.block-modules-image {
  position: absolute;
  bottom: -100px;
  right: 0;
  width: 45%;
  z-index: 5;
  pointer-events: none;
}
@media only screen and (max-width: 600px) {
  .block-modules {
    color: var(--color-white);
    padding: 0;
  }
  .block-modules-wrapper {
    padding: 60px 0 100px 0;
    border-radius: 0;
  }
  .block-modules-image {
    position: absolute;
    bottom: -100px;
    right: -20%;
    width: 100%;
    z-index: 5;
    pointer-events: none;
  }
}
</style>
