<template>
  <section class="block-customizations gb-section" v-for="item in customization" :key="item.id">
    <img :src="item.bgImage" class="box-bg-image" v-if="item.bgImage" alt="background" />
    <div class="box-bg-image-effect" v-if="item.bgImage"></div>
    <div class="block-customizations-wrapper gb-wrapper">
      <h2 class="fn-color secondary mb-20" :class="{ white: !!item.bgImage }" v-if="item.title">{{ item.title }}</h2>
      <p v-if="item.description" class="fn-color" :class="{ white: !!item.bgImage }">{{ item.description }}</p>
      <UltraColText columns="one" data-aos="zoom-in-left" v-if="item.image" class="mt-20">
        <UltraLink :action="item.action" v-if="item.action">
          <img class="img-rounded" :src="item.image" alt="image" />
          <i class="icon-play-fill ultra-large img-icon" v-if="item.url === '#video'"></i>
        </UltraLink>
        <img class="img-rounded border" :src="item.image" alt="image" v-else />
      </UltraColText>
      <h3 class="fn-color secondary mt-20" :class="{ white: !!item.bgImage }" v-if="item.bottom_title">{{ item.bottom_title }}</h3>
      <p v-if="item.bottom_description" class="fn-color" :class="{ white: !!item.bgImage }">{{ item.bottom_description }}</p>
    </div>
  </section>
</template>

<script>
import UltraColText from "@/components/UltraColText";
import UltraLink from "@/components/UltraLink";

export default {
  name: "BlockCustomization",
  components: {UltraLink, UltraColText},
  computed: {
    customization() {
      return this.$store.getters['customization'];
    },
  },
}
</script>

<style scoped>
.block-customizations-wrapper {
  margin-top: 100px;
  margin-bottom: 100px;
}
h2,
h3,
p {
  text-align: center;
}
</style>