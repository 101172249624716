<template>
  <section class="ultra-box gb-section">
    <div class="ultra-box-wrapper gb-wrapper">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: "UltraBox",
}
</script>

<style scoped>
.ultra-box {
  padding: 100px 0;
  background-color: var(--color-white);
}
.ultra-box-wrapper {
  flex-direction: row;
  flex-wrap: wrap;
}
</style>