<template>
  <div class="ultra-btn bg-color" v-if="action" :class="[size, color]" @click.prevent="execAction(action)">
    <div class="ultra-btn-icon" v-if="action.icon">
      <i :class="`icon-${action.icon} small`" />
    </div>
    <div class="ultra-btn-text" v-if="action.text" v-html="action.text" />
    <div class="ultra-btn-text slot" v-else-if="!!$slots.default">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "UltraBtn",
  mixins: [mixins],
  props: {
    size: {
      type: String,
      default: 'normal',
    },
    color: {
      type: String,
      default: 'secondary',
    },
    action: {
      type: Object,
      required: false,
    },
  },
}
</script>

<style scoped>
.ultra-btn {
  font-size: 1.2rem;
  line-height: 1.2rem;
  min-height: 60px;
  font-weight: 600;
  padding: 20px 30px;
  border: none;
  color: currentColor;
  border-radius: 10px;
  transition: all ease-in-out .3s;
  cursor: pointer;
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
}
.ultra-btn.small {
  font-size: 1.2rem;
  line-height: 1.2rem;
  min-height: 40px;
  font-weight: 400;
  padding: 10px 25px;
  border: none;
  color: currentColor;
  border-radius: 5px;
  transition: filter ease-in-out .3s;
  cursor: pointer;
}
.ultra-btn.textSmall {
  font-size: .9rem;
  line-height: .9rem;
  text-transform: none;
}
.ultra-btn.rounded {
  border-radius: 50%;
  padding: 12px;
}
.ultra-btn.big {
  width: 60px;
  height: 60px;
  min-height: unset;
  padding: 10px;
}
.ultra-btn.medium {
  width: 42px;
  height: 42px;
  min-height: unset;
  padding: 10px;
}
.ultra-btn.big i {
  width: 36px;
  height: 36px;
  margin-left: 2px;
}
.ultra-btn.medium i {
  width: 26px;
  height: 26px;
}
.ultra-btn-icon {
  margin-right: 0;
  margin-top: 1px;
  margin-left: 0;
}
.ultra-btn-icon + div {
  margin-left: 10px;
}
.ultra-btn-text {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-align: center;
}
.textOnly .ultra-btn-text {
  font-weight: 300;
  white-space: nowrap;
}
.textOnly .ultra-btn-text b {
  font-weight: 500;
}
.textUnderline .ultra-btn-text {
  text-decoration: underline;
}

.ultra-btn:hover {
  filter: brightness(1.40);
  transform: translate3d(0, -2px, 0);
  transition: all ease-in-out .3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .4);
}
</style>
