<template>
  <ul class="ultra-accordion-list" v-if="items">
    <li class="ultra-accordion-item" v-for="item in items" :key="item.id" data-aos="zoom-in-up" :ref="item.id"
        :class="{ active: item.id === active }"
        @mouseenter.prevent="showItem(item.id)"
        @click.prevent="toggleItem(item.id)"
    >
      <header class="ultra-accordion-item-header"
              :class="{ active: item.id === active }"
      >
        <img class="ultra-accordion-item-icon" :src="item.icon" :alt="item.title" />
        <h4 class="mt-20" v-if="item.title">{{ item.title }}</h4>
      </header>
      <section class="ultra-accordion-item-section" v-if="item.id === active" data-aos="fade-in">
        <UltraColText :columns="item.image ? 'two' : 'one'">
          <p class="ultra-accordion-item-section-description" v-if="item.description" v-html="item.description"></p>
          <UltraBtn :action="item.action" v-if="item.action" />
        </UltraColText>
        <UltraColText class="ultra-accordion-item-image" columns="two" v-if="item.image">
          <img class="ultra-accordion-item-image-image" data-aos="slide-left" :src="item.image" :alt="item.title" />
        </UltraColText>
      </section>
    </li>
  </ul>
</template>

<script>
import UltraColText from "@/components/UltraColText";
import UltraBtn from "@/components/UltraBtn";
import mixins from "@/mixins";

export default {
  name: "UltraAccordion",
  mixins: [mixins],
  data() {
    return {
      active: null,
    };
  },
  mounted() {
    this.initialState();
  },
  components: {UltraBtn, UltraColText},
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    toggleItem(item) {
      const { active } = this;
      this.active = active === item ? null : item;
      // setTimeout(() => { this.setScroll() }, 500);
    },
      showItem(item) {
          this.active = item;
          // setTimeout(() => { this.setScroll() }, 500);
      },
    setScroll() {
      const { active } = this;
      if (!active) return;
      if (this.$refs[active]) this.scrollTopOnView(this.$refs[active][0]);
    },
    initialState() {
      // const { items } = this;
      // if (!items) return;
      // this.active = items[0].id;
    },
  },
}
</script>

<style scoped>
.ultra-accordion-list {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding: 0 20px;
  list-style: none;
  flex-wrap: wrap;
}
.ultra-accordion-item {
  margin: 20px;
  width: calc(25% - 40px);
  height: 200px;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  text-align: left;
  padding: 0;
  position: relative;
  z-index: 1;
}
.ultra-accordion-item.active {
  z-index: 5;
}
.ultra-accordion-item-header {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 0 20px;
  position: relative;
  cursor: pointer;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .2), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  background-size: 100% 200%;
  background-position: left bottom;
  transition: all ease-in-out .3s;
  box-shadow: 0 2px 4px rgba(255, 255, 255, .2);
  border-radius: 15px;
  background: var(--color-secondary);
  z-index: 2;
}
.ultra-accordion-item-header:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .2), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  background-size: 100% 200%;
  background-position: left top;
  transition: all ease-in-out .3s;
}
.ultra-accordion-item-icon {
  width: 72px;
  height: 72px;
  object-position: center center;
  object-fit: contain;
}
.ultra-accordion-item-header:before {
  content: '';
  width: 15px;
  height: 15px;
  border-bottom: 2px solid var(--color-white);
  border-left: 2px solid var(--color-white);
  position: absolute;
  right: 24px;
  top: 18px;
  transform: rotate(-45deg);
  transition: all ease-in-out .3s;
  opacity: .4;
}
.ultra-accordion-item-header.active:before {
  top: 24px;
  transform: rotate(-225deg);
  transition: all ease-in-out .3s;
}
.ultra-accordion-item-header.active {
  z-index: 6;
}

.ultra-accordion-item-section {
  position: absolute;
  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
  margin: 20px 20px 40px 20px;
  width: calc(300% + 40px);
  top: 50%;
  left: 0;
  background: #D9D9D9;
  z-index: 4;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .4);
  padding: 20px;
  color: var(--color-black);
}
.ultra-accordion-item:nth-child(4n) .ultra-accordion-item-section,
.ultra-accordion-item:nth-child(4n - 1) .ultra-accordion-item-section {
  left: unset;
  right: 0;
}
.ultra-accordion-item-section-description {
  margin: 80px 0;
}
.ultra-accordion-item-image {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  overflow: hidden;
  margin-right: -20px;
}
.ultra-accordion-item-image-image {
  max-width: calc(100% + 60px);
  box-shadow: -2px 2px 6px rgba(0, 0, 0, .5);
}
@media only screen and (max-width: 600px) {
  .ultra-accordion-list {
    padding: 0;
  }
  .ultra-accordion-item-section {
    text-align: left;
  }

  .ultra-accordion-item {
    margin: 0 0 20px 0;
    width: 100%;
    height: auto;
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    text-align: left;
    padding: 0;
    position: relative;
    z-index: 1;
  }

  .ultra-accordion-item-header {
    flex-direction: row;
    justify-content: flex-start;
    padding-right: 40px;
    height: 80px;
  }
  .ultra-accordion-item-header h4 {
    margin-top: 0 !important;
    text-align: left;
  }
  .ultra-accordion-item-icon {
    width: 42px;
    height: 42px;
    object-position: center center;
    object-fit: contain;
    margin-right: 10px;
  }

  .ultra-accordion-item-section {
    width: calc(100% + 40px);
    left: -40px;
    top: unset;
    text-align: center;
    padding-right: 20px;
    position: relative;
    margin-top: -10px;
    margin-bottom: 10px;
    flex-direction: column;
  }
  .ultra-accordion-item:nth-child(4n) .ultra-accordion-item-section,
  .ultra-accordion-item:nth-child(4n - 1) .ultra-accordion-item-section {
    left: -40px;
    right: unset;
  }
  .ultra-accordion-item-section-description {
    margin: 10px 0;
  }
  .ultra-accordion-item-image {
    overflow: visible;
  }
  .ultra-accordion-item-image-image {
    max-width: 100%;
  }
}
</style>
