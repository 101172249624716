<template>
  <UltraBox class="block-comments" v-if="comments">
    <header class="block-comments-header" data-aos="zoom-in-up">
      <h2 class="fn-color primary tx-uppercase">Depoimentos</h2>
    </header>
    <section class="block-comments-section gb-section">
      <Carousel :settings="settings" :breakpoints="breakpoints">
        <Slide v-for="item in comments" :key="item.id">
          <UltraCardComment :comment="item" />
        </Slide>
        <template #addons>
          <Pagination />
          <Navigation />
        </template>
      </Carousel>
    </section>
  </UltraBox>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import UltraBox from "@/components/UltraBox";
import UltraCardComment from "@/components/UltraCardComment";

export default {
  name: "BlockComments",
  components: {
    UltraCardComment,
    UltraBox,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  computed: {
    comments() {
      return this.$store.getters['comments'];
    },
    settings() {
      return {
        itemsToShow: 1,
        snapAlign: 'start',
      };
    },
    breakpoints() {
      return {
        600: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
      };
    },
  },
}
</script>

<style scoped>
.block-comments {
  text-align: center;
  width: 100%;
  position: relative;
}
.block-comments-header {
  width: 100%;
  margin-bottom: 40px;
}
.block-comments-section {
  max-width: 100%;
}
@media only screen and (max-width: 600px) {
  .block-comments-section {
    padding: 0 20px;
  }
}
</style>
