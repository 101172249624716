<template>
  <div class="ultra-carousel" v-if="slides">
    <Carousel :settings="settings" :breakpoints="breakpoints">
      <Slide v-for="(item, index) in slides" :key="index">
        <div class="ultra-carousel-item">
          <img :src="item" :alt="index" />
        </div>
      </Slide>
      <template #addons>
        <Pagination />
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  name: "UltraCarousel",
  props: {
    slides: {
      type: Array,
      required: false,
    },
    itemsToShow: {
      type: Number,
      default: 1,
    },
    autoplay: {
      type: Number,
      default: 7000,
    },
  },
  computed: {
    settings() {
      const { itemsToShow } = this;
      return {
        itemsToShow: (itemsToShow > 1) ? Math.round(itemsToShow / 2) : 1,
        snapAlign: 'start',
      };
    },
    breakpoints() {
      const { itemsToShow } = this;
      return {
        600: {
          itemsToShow,
          snapAlign: 'start',
        },
      };
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
}
</script>
<style scoped>
.ultra-carousel {
  width: 100%;
}
.ultra-carousel-item {
  margin: 10px 10px 0 10px;
}

@media only screen and (max-width: 600px) {
  .ultra-carousel {
    padding: 0;
    margin-left: -20px;
    width: calc(100% + 40px);
  }
  .ultra-carousel-item {
    margin: 0;
  }
}
</style>
