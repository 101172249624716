<template>
  <UltraBox v-if="drops">
    <UltraColText class="mb-20">
      <h2 v-if="drops.title" class="mb-20 tx-uppercase fn-color primary-dark" data-aos="zoom-in-down">{{ drops.title }}</h2>
      <h3 v-if="drops.description" class="mb-20" data-aos="zoom-in-down">{{ drops.description }}</h3>
    </UltraColText>
    <UltraColText columns="four" v-for="item in drops.items" :key="item.id">
      <UltraCard :card="item" />
    </UltraColText>
  </UltraBox>
</template>

<script>
import UltraColText from "@/components/UltraColText";
import UltraBox from "@/components/UltraBox";
import UltraCard from "@/components/UltraCard";
export default {
  name: "BlockDrops",
  components: {UltraCard, UltraBox, UltraColText},
  computed: {
    drops() {
      return this.$store.getters['drops'];
    },
  },
}
</script>

<style scoped>
.block-drops {
  background: var(--color-white);
}
.block-drops {
  text-align: center;
  padding: 80px 0;
}
h2 {
  text-align: center;
}
</style>