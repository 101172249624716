<template>
  <section class="block-slides gb-section" v-if="slides">
    <div class="block-slides-wrapper gb-wrapper mb-20">
      <UltraColText columns="two">
        <img src="@/assets/images/notebooks-shared.png" class="block-slides-image" :alt="slides.title" />
      </UltraColText>
      <UltraColText columns="two">
        <h2 class="mb-20 tx-uppercase" data-aos="zoom-in-down" v-if="slides.title">{{ slides.title }}</h2>
        <p class="mb-20" data-aos="zoom-in-down" v-if="slides.description" v-html="slides.description"></p>
      </UltraColText>
    </div>
    <div class="block-slides-wrapper gb-wrapper mt-20" v-if="slides.images">
      <UltraCarousel class="dark" :items-to-show="2" :slides="slides.images" />
    </div>
  </section>
</template>

<script>
import UltraColText from "@/components/UltraColText.vue";
import UltraCarousel from "@/components/UltraCarousel.vue";

export default {
  name: "BlockSlides",
  components: {UltraCarousel, UltraColText},
  computed: {
    slides() {
      return this.$store.getters['slides'];
    },
  },
}
</script>

<style scoped>
.block-slides {
  background-image: linear-gradient(90deg, #868686 10.43%, #454545 93.74%);
  color: var(--color-white);
  padding: 120px 0 0 0;
}
.block-slides-wrapper {
  display: inline-flex;
  flex-direction: row;
}
.block-slides-image {
  position: absolute;
  top: -148px;
  left: -60px;
}

@media only screen and (max-width: 600px) {
  .block-slides-wrapper {
    flex-direction: column;
  }
  .block-slides-image {
    position: relative;
    top: unset;
    left: unset;
    margin-top: -159px;
  }
  .block-slides-wrapper {
    text-align: center;
  }
}
</style>
