const mixins = {
    methods: {
        execAction(action = null) {
            if (!action) {
                this.sendClick();
                return;
            }
            setTimeout(() => {
                this.$store.commit('closeMenu');
            }, 300);
            const { type } = action;
            switch (type) {
                case 'link':
                    this.openLink(action);
                    return;

                case 'scroll':
                    this.scrollTo(action);
                    return;

                case 'modal':
                    this.openModal(action);
                    return;

                default:
                    this.sendClick();
                    return;
            }
        },
        sendClick() {
            this.$emit('click');
            this.$emit('actionClick');
        },
        openLink({ url, target }) {
            window.open(url, target ?? '_blank');
        },
        scrollTo({ url }) {
            const eventScroll = new CustomEvent('canScroll', { detail: url });
            window.dispatchEvent(eventScroll);
        },
        openModal({ url, options }) {
            const { title, icon, orientation, } = options ?? { title: null, icon: null };
            const payload = {
                title: title ?? null,
                icon: icon ?? null,
                url: url ?? null,
                orientation: orientation ?? 'portrait',
            };
            this.$store.dispatch('modal', payload);
        },
        hideModal() {
            this.$store.commit('hideModal');
        },

        canScroll({ detail }) {
            if (!detail) return;
            if (this.$refs[detail]) this.scrollTopOnView(this.$refs[detail].$el);
        },
        scrollTopOnView(element) {
            const offset = -90;
            const yTop = element.getBoundingClientRect().top + window.pageYOffset + offset;
            window.scrollTo({ top: yTop, behavior: "smooth" });
        },
        scrollTopPage() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    }
};

export default mixins;
