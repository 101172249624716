<template>
  <a v-if="action"
     :href="action.url ?? '#'"
     :target="action.target ?? '_self'"
     @click.prevent="execAction(action)"
  >
    <i :class="`icon-${action.icon}`" v-if="action.icon"></i>
    <span v-else>{{ action.text }}</span>
    <slot></slot>
  </a>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "UltraLink",
  mixins: [mixins],
  props: {
    action: {
      type: Object,
      required: false,
    },
  },
}
</script>

<style scoped>

</style>