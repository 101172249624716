import { createStore } from "vuex";

const store = createStore({
    state () {
        return {
            menu: null,
            social: null,
            signIn: null,
            top: null,
            highlight: null,
            modules: null,
            slides: null,
            movie: null,
            drops: null,
            outdoor: null,
            comments: null,
            lgpd: null,
            footer: null,
            customization: null,
            buttons: null,
            modal: {
                show: false,
                title: null,
                icon: null,
                url: null,
            },
            menuActive: false,
        }
    },
    getters: {
        menu(state) {
            return state.menu;
        },
        social(state) {
            return state.social;
        },
        signIn(state) {
            return state.signIn;
        },
        top(state) {
            return state.top;
        },
        highlight(state) {
            return state.highlight;
        },
        modules(state) {
            return state.modules;
        },
        slides(state) {
            return state.slides;
        },
        movie(state) {
            return state.movie;
        },
        drops(state) {
            return state.drops;
        },
        outdoor(state) {
            return state.outdoor;
        },
        comments(state) {
            return state.comments;
        },
        buttons(state) {
            return state.buttons;
        },
        lgpd(state) {
            return state.lgpd;
        },
        footer(state) {
            return state.footer;
        },
        customization(state) {
            return state.customization;
        },
        modal(state) {
            return state.modal;
        },
        menuActive(state) {
            return state.menuActive;
        },
    },
    mutations: {
        loader (state, payload) {
            const { slides, footer, lgpd, page, menu, social, top, highlight, modules, movie, drops, outdoor, comments, buttons, signIn, customization } = payload;
            state.menu = page && page.menu === true ? menu : !page ? menu : null ;
            state.social = page && page.social === true ? social : !page ? social : null;
            state.signIn = page && page.signIn === true ? signIn : !page ? signIn : null;
            state.top = page && page.top === true ? top : !page ? top : null;
            state.highlight = page && page.highlight === true ? highlight : !page ? highlight : null;
            state.modules = page && page.modules === true ? modules : !page ? modules : null;
            state.slides = page && page.slides === true ? slides : !page ? slides : null;
            state.movie = page && page.movie === true ? movie : !page ? movie : null;
            state.drops = page && page.drops === true ? drops : !page ? drops : null;
            state.outdoor = page && page.outdoor === true ? outdoor : !page ? outdoor : null;
            state.comments = page && page.comments === true ? comments : !page ? comments : null;
            state.buttons = page && page.buttons === true ? buttons : !page ? buttons : null;
            state.lgpd = page && page.lgpd === true ? lgpd : !page ? lgpd : null;
            state.footer = page && page.footer === true ? footer : !page ? footer : null;
            state.customization = page && page.customization === true ? customization : !page ? customization : null;
        },
        showModal (state) {
            const { modal } = state;
            modal.show = true;
            state.modal = modal;
        },
        hideModal (state) {
            const { modal } = state;
            modal.show = false;
            state.modal = modal;
        },
        modal (state, payload) {
            state.modal = payload;
        },
        toggleMenu (state) {
            const { menuActive } = state;
            state.menuActive = !menuActive;
        },
        closeMenu (state) {
            state.menuActive = false;
        },
    },
    actions: {
        modal (context, payload) {
            const baseModal = {
                show: false,
                title: null,
                icon: null,
                url: null,
                orientation: 'portrait',
            };
            context.commit('modal', {
                ...baseModal,
                ...payload,
            });
            context.commit('showModal');
        },
    },
});

export default store;
