<template>
  <div class="ultra_col_text" :class="`col-${columns}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "UltraColText",
  props: {
    columns: {
      type: String,
      default: 'one',
    },
  },
}
</script>

<style scoped>

</style>